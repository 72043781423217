import React from 'react';
import {Button, Form, Spinner} from "react-bootstrap";
import './upload_form.scss';

export default class UploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { file: '' };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.copy = this.copy.bind(this);
  }

  toggleSpinner() {
    const spinner = document.querySelector('form .spinner-border'),
          submit_btn = document.querySelector('form button[type="submit"]')

    submit_btn.classList.toggle('hidden')
    spinner.classList.toggle('hidden')
  }

  handleChange(e) {
    this.setState({file: e.target.files[0]});
  }

  handleSubmit(e) {
    e.preventDefault();
    this.toggleSpinner();

    const formData = new FormData();
    formData.append('file', this.state.file)

    fetch('/api/extractInfo', {
      method: 'POST',
      body: formData
    })
        .then(response => response.json())
        .then(result => {
          const textarea = document.querySelector('form textarea')
          textarea.value = JSON.stringify(result, null, 4);
          this.toggleSpinner();
          textarea.closest('.form-group').scrollIntoView({ behavior: 'smooth' });
        })
        .catch(error => {
          console.error('Error', error)
          alert('Document processing failed!');
          this.toggleSpinner();
        })
  }

  copy() {
    const textarea = document.querySelector('form textarea'),
          copy_text = document.querySelector('.copy-text');
    textarea.select();
    document.execCommand('copy');

    copy_text.classList.toggle('visible');
    setTimeout(() => copy_text.classList.toggle('visible'), 3000);
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group>
          <Form.File accept='.pdf' name='file' onChange={this.handleChange} required />
          <Button type='submit'>Upload</Button>
          <Spinner className={'hidden'} animation="border" />
        </Form.Group>
        <Form.Group>
          <Form.Label>JSON Output</Form.Label>
          <Form.Control as="textarea" rows={10} />
          <Button onClick={this.copy}>Copy</Button>
          <p className={'copy-text'}>JSON Output has been copied to your clipboard.</p>
        </Form.Group>
      </Form>
    )
  }
}