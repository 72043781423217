import React from 'react';
import ReactDOM from 'react-dom';
import {Header, Footer} from 'lilw-react-components';
import {Container, Row, Col} from "react-bootstrap";
import UploadForm from "./upload_form";
import './index.scss'

ReactDOM.render([
    <Header a11y_header_desc='KIID Onboarding Tool'/>,
    <main>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={10}>
            <h1 className='text-center'>KIID Onboarding Tool</h1>
            <h5 className="text-center">
              The KIID Onboarding Tool has been developed as part of 
              a <a href="https://zeidler.group/insights/zeidler-group-announces-strategic-partnership-with-the-legal-innovation-lab-wales-to-build-ai-solutions-for-legal-delivery/">collaboration partnership</a> between 
              the <a href="https://legaltech.wales">Legal Innovation Lab Wales</a> and&nbsp;
              <a href="https://zeidler.group">Zeidler</a>.</h5>

            <h5>The tool has been developed by <a href="http://www.liviorobaldo.com/">Livio Robaldo</a>, researcher 
                in Legaltech at the Legal Innovation Lab Wales. 
                Extensions of the tool into a full entity linking platform able to process documents with respect to 
                the <a href="https://spec.edmcouncil.org/fibo/"> Financial Industry Business Ontology (FIBO)</a> are currently under investigation.</h5>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs={12} md={10}>
            <UploadForm />
          </Col>
        </Row>
      </Container>
    </main>,
    <Footer/>
  ],
  document.getElementById('root')
);
